<template>
  <div>
    <div v-if="importPopup" class="popup_overlay relative px-4">
      <div style="width: 700px;" class="custom_dialog rounded-xl h-min">
        <div  class=" bg-primary flex justify-between p-3 sticky top-0">
          <p class="heading-4 text-white">Create New List</p>
        </div>
        <div class="p-4">
          <div  class="">
            <div class="pb-2">
              <TextField
                :inputId="'listName1'"
                :showcharLimit="false"
                :inputext="listName"
                :placholderText="`List Name`"
                :lableText="'List Name'"
                :autoFocus="false"
                :fieldError="listNameErr !== ''"
                @blurAction="blurListName()"
                @keyPressAction="listNameErr = ''"
                @inputChangeAction="(data) => listName = data"  />
                <p class="text-error heading-7">{{listNameErr}}</p>
            </div>
          </div>
          <div class="flex items-baseline flex-wrap">
            <!-- <div class="pb-2 col-span-2">
              <TextField :disabled="true"
                :inputId="'fileName1'"
                :showcharLimit="false"
                :inputext="contactListName"
                :placholderText="`File Name`"
                :lableText="'File Name'"
                :autoFocus="false"
                :fieldError="fileNameErr !== ''"
                @keyPressAction="fileNameErr = ''"
                @inputChangeAction="(data) => contactListName = data"  />
                <p class="text-error heading-7">{{fileNameErr}}</p>
            </div> -->
            <div class="text-center pt-2">
              <!-- <input id="files" type="file" ref="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style="display: none" @change="uploadFiles"/> -->
              <input id="files" type="file" ref="file" accept=".csv" style="display: none" @change="uploadFiles"/>
              <button type="button" class="bg-primary text-white hover:bg-opacity-75  shadow  hover:shadow-xl global_btn rounded-xl cursor-pointer py-2 px-12 cta-text" @click="$refs.file.click()">
                <i class="fa-solid fa-file-import"></i> {{contactListName === '' ? 'Browse...' : 'Change File'}}
              </button>
              <div class="heading-7 text-error" v-if="fileNameErr">{{fileNameErr}}</div>
            </div>
            <div class="pl-4 heading-4 text-text2" v-if="contactListName !== ''">
              <i class="fa-solid fa-file-csv"></i>
              <span class="pl-2">{{ contactListName }}</span>
            </div>
          </div>
        </div>
        <div class="flex gap-2 justify-end p-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="cancelBtn"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Next Select Mapping'" @buttonAction="saveList"/>
        </div>
      </div>
    </div>
    <div v-if="openConfiPopup" class="popup_overlay relative px-4">
      <div style="width: 1200px;max-height: 95%;" class="custom_dialog rounded-xl h-min">
        <div  class=" bg-primary flex justify-between p-3 sticky top-0">
          <p class="heading-4 text-white">Column Mapping: <span class="pl-1 text-white">{{ listName }}</span></p>
        </div>
        <div class="overflow-auto" style="max-height: 75vh">
          <div class="table_containder m-4" v-if="!mobileView">
            <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-7 uppercase px-3 py-3 items-center">
              <div class="date_col flex items-center p-1">
                <span class="cursor-pointer">Matched</span>
              </div>
              <div class="org_name flex items-center p-1">
                <span class="cursor-pointer">Column Header From File</span>
              </div>  
              <div class="org_name flex items-center p-1">
                <span class="cursor-pointer">Preview Information</span>
              </div>  
              <div class="org_name flex items-center p-1">
                <span class="cursor-pointer">Contact Fields</span>
              </div>  
            </div>
            <div v-for="(data, index) in finalArray" :key="index" class="min-w-min group   flex border-r border-l border-b border-gray1  bg-white  text-sm px-3 py-2 items-center">
              <div class="date_col p-1" >
                <span class="heading-5 text-text2">
                  <p v-if="data.isDataMatched" class="text-success heading-4"><i class="fas fa-check-circle"></i></p>
                  <span v-if="!data.isDataMatched" class="text-error heading-4"><i class="fas fa-exclamation-triangle fa-beat-fade"></i></span>
                </span>
              </div>
              <div class="org_name p-1" >
                <span class="heading-5 text-text2 overFlowParaA">{{data.columnHeader}}</span>
              </div>
              <div class="org_name p-1">
                <span class="heading-5 text-text2 ">
                  <div v-for="(subData, subindex) in data.preview" :key="subindex">
                    <span>{{subData}}</span>
                  </div>
                </span>
              </div>
              <div class="org_name p-1">
                <div>
                  <Dropdown
                    :inputext="data.csvColumnName" 
                    :inputId="'id'+index"
                    :lableText="''"
                    :placholderText="`Not Mapped`"
                    :autoFocus="false"
                    :showArrow="true"
                    :setReadOnly="false"
                    :keyName="'label'"
                    :listId="'dbColumnName'"
                    @onClickInputAction="onClickInputAction(index)"
                    :items="arrayFromTable"
                    @selectNewForVal="selectNumber"
                  />
                </div>
              </div>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;" class=""  v-if="mobileView">
          <div v-for="(data, index) in finalArray" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" style="overflow: visible !important;">
            <div class="flex items-center ">
              <div class="w-12  items-center">
                  <p v-if="data.isDataMatched" class="text-success heading-4"><i class="fas fa-check-circle"></i></p>
                  <span v-if="!data.isDataMatched" class="text-error heading-4"><i class="fas fa-exclamation-triangle fa-beat-fade"></i></span>
              </div>
              <div :class="isVerysmall ? '' : 'flex justify-between items-center'" class=" w-full">
                <div class="">
                  <p class="text-text2 heading-5 overFlowParaA mb-1">{{data.columnHeader}}</p>
                  <div class="text-gray4 heading-6">
                    <div class="ehading-6 text-text2">Preview:</div>
                    <div v-for="(subData, subindex) in data.preview" :key="subindex" class="pl-2">
                      {{ subData}}
                    </div>
                  </div>
                </div>
                <div class="w-52">
                  <mobileDropdown
                    :inputext="data.csvColumnName" 
                    :inputId="'id'+index"
                    :lableText="''"
                    :placholderText="`Select`"
                    :autoFocus="false"
                    :showArrow="true"
                    :setReadOnly="false"
                    :keyName="'label'"
                    :listId="'label'"
                    @onClickInputAction="onClickInputAction(index)"
                    :items="arrayFromTable"
                    @selectNewForVal="selectNumber"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="text-center justify-center flex  heading-5 py-1 text-error" v-if="phoneErr || firstNameErr || stateErr">Please Select {{ errorMessage }} value.</div>
        <!-- <div class="text-center justify-center flex  heading-5 py-1 text-error" v-if="phoneErr || firstNameErr || stateErr">Please Select {{firstNameErr ? 'First Name Field' : ''}} {{phoneErr ? ',Phone Number Contact Field' : ''}} {{stateErr ? ',State Name Field' : ''}}  value.</div> -->
        <div class="text-center justify-center flex  heading-5 py-1 text-error" v-if="totalunSelCount > 0">You have <span class="font-semibold px-1"> {{ totalunSelCount }} </span>unselected columns.</div>
        <div class="sticky bottom-0 flex p-2 px-4 flex-wrap justify-between">
          <div class="flex gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Back'" @buttonAction="backBtn"/>
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="cancelBtn"/>
          </div>
          <div class="flex gap-2">
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Create List'" @buttonAction="saveApi()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import mobileDropdown from '@/View/components/mobileDropdown.vue'
import Dropdown from '@/View/components/dropdown.vue'
import Papa from "papaparse";
import TextField from '@/View/components/textfield.vue'
import Button from '@/View/components/globalButton.vue'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    mobileDropdown,
    Dropdown,
    TextField,
    Button
  },
  mixins: [deboucneMixin],
  props: ['orgId'],
  data () {
    return {
      phoneErr: false,
      firstNameErr: false,
      stateErr: false,
      isVerysmall: false,
      totalunSelCount: 0,
      finalArray: [],
      openConfiPopup: false,
      listNameErr: '',
      fileNameErr: '',
      contactListName: '',
      listName: '',
      importPopup: true,
      mobileView: false,
      mainArray: [],
      selectedSelectionId: 0,
      files: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  computed: {
    errorMessage() {
      const errors = [];
      if (this.firstNameErr) errors.push('First Name Field');
      if (this.phoneErr) errors.push('Phone Number Contact Field');
      if (this.stateErr) errors.push('State Name Field');
      return errors.join(', ');
    }
  },
  mounted () {
    this.getDatabaseDetail()
    this.resizeWindowHandler();
    document.title = 'Add List'
  },
  watch: {
    finalArray: {
      handler () {
        console.log('finalArrayfinalArray', this.finalArray)
        if (this.finalArray.length > 0) {
          this.totalunSelCount = 0
          this.finalArray.forEach(element => {
            if (!element.isDataMatched) {
              this.totalunSelCount ++
            }
          });
        }
      },
      deep: true
    }
  },
  methods: {
    blurListName () {
      if (this.listName !== '') {
        ADMINAPI.CheckListNameExist(
          this.listName,
          response => {
            if (response.Data) {
              this.listNameErr = 'List name is already exist'
            } else {
              this.listNameErr = ''
            }
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else {
        this.listNameErr = ''
      }
    },
    onClickInputAction (data) {
      this.selectedSelectionId = data
      console.log('onClickInputAction', data)
      this.phoneErr = false
      this.stateErr = false
      this.firstNameErr = false
    },
    selectNumber (data) {
      console.log('datadatadata', data)
      if (data !== null) {
        this.finalArray[this.selectedSelectionId].csvColumnName = data.label
        this.finalArray[this.selectedSelectionId].dbColumnName = data.dbColumnName
        this.finalArray[this.selectedSelectionId].isDataMatched = true
        data.isDisable = true
        setTimeout(() => {
          this.arrayChanges()
        }, 200);
      } else {
        setTimeout(() => {
          this.arrayChanges()
        }, 200);
        // console.log('this.finalArray[this.selectedSelectionId]',this.finalArray[this.selectedSelectionId])
        // this.arrayFromTable.forEach((err) => {

        // })
        this.finalArray[this.selectedSelectionId].csvColumnName = ''
        this.finalArray[this.selectedSelectionId].dbColumnName = ''
        this.finalArray[this.selectedSelectionId].isDataMatched = false
      }
    },
    arrayChanges () {
      for (let II = 0; II < this.arrayFromTable.length; II++) {
        if (this.arrayFromTable[II].isDisable) {
          console.log('YYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYYY', this.arrayFromTable[II])
          for (let index = 0; index < this.finalArray.length; index++) {
            if (this.finalArray[index].csvColumnName.trim().toLowerCase() === this.arrayFromTable[II].label.trim().toLowerCase()) {
              this.arrayFromTable[II].isDisable = true
              break
            } else {
              this.arrayFromTable[II].isDisable = false
            }
          }
        }
      }
      // for (let II = 0; II < this.arrayFromTable.length; II++) {
      //   for (let JJ = 0; JJ < this.finalArray.length; JJ++) {
      //     if (this.finalArray[JJ].csvColumnName === this.arrayFromTable[JJ].label) {
      //       this.arrayFromTable[JJ].isDisable = true
      //       console.log('MATCHED))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))))', this.finalArray[JJ].csvColumnName)
      //     } else {
      //       this.arrayFromTable[JJ].isDisable = false
      //     }
      //   }
      // }
    },
    backBtn () {
      this.importPopup = true
      this.openConfiPopup = false
    },
    cancelBtn () {
      this.listName = ''
      this.contactListName = ''
      this.listNameErr = ''
      this.fileNameErr = ''
      this.importPopup = false
      this.files = []
      this.$emit('closeSelecion', false)
    },
    saveList () {
      let isErr = false
      if (this.listName === '') {
        this.listNameErr = 'List name is required'
        isErr = true
      }
      if (this.contactListName === '') {
        this.fileNameErr = 'Please Select .csv file'
        isErr = true
      }
      if (this.listNameErr !== '') {
        isErr = true
      }
      if (!isErr) {
        this.importPopup = false
        this.openConfiPopup = true
      }
    },
    async uploadFiles (event) {
      this.finalArray = []
      this.mainArray = []
      let file = []
      this.files = []
      console.log('event', event.target.files)
      this.contactListName = event.target.files[0].name
      this.fileNameErr = ''
      file = event.target.files[0]
      this.files.push(event.target.files[0])
      Papa.parse(file, {
        complete: (result) => {
          this.displayData(result.data);
        },
        header: true,
        dynamicTyping: true,
      });
      this.$refs.file.value = ''
    },
    displayData(data) {
      const numRowsToDisplay = 2;
      let headerArray = Object.keys(data[0]);
      let rowArray = []
      for (let i = 0; i < numRowsToDisplay && i < data.length; i++) {
        rowArray.push(data[i]);
      }
      console.log('rowArray', rowArray)
      for (let i = 0; i < headerArray.length; i++) {
        let Arr = {
          columnHeader: headerArray[i],
          mainArr: rowArray
        }
        this.mainArray.push(Arr)
      }
      this.filterObjects(this.mainArray);
      setTimeout(() => {
        this.finalArray = []
        console.log(">............................ HOPE>>>>>>>>>>>>>>>>>>>>>>>>>>>>..................", this.mainArray);
        for (let index = 0; index < this.mainArray.length; index++) {
          let Object = {
            columnHeader: this.mainArray[index].columnHeader,
            preview: this.mainArray[index].mainArr,
            csvColumnName: '',
            dbColumnName: '',
            isDataMatched: false,
          }
          for (let JJ = 0; JJ < this.arrayFromTable.length; JJ++) {
            if (this.arrayFromTable[JJ].label.trim().toLowerCase() === this.mainArray[index].columnHeader.trim().toLowerCase()) {
              Object.csvColumnName = this.mainArray[index].columnHeader
              Object.dbColumnName = this.arrayFromTable[JJ].dbColumnName
              Object.isDataMatched = true
              this.arrayFromTable[JJ].isDisable = true
            }
          }
          this.finalArray.push(Object)
        }
        console.log(">............................ this.finalArray>>>>>>>>>>>>>>>>>>>>>>>>>>>>..................", this.finalArray);
      }, 200);
    },
    filterObjects () {
    const newDataArray = [];
    this.mainArray.forEach((headerObj) => {
      const columnHeader = headerObj.columnHeader;
      const mainArr = headerObj.mainArr;
      const filteredMainArr = mainArr.map((obj) => {
        // const newObj = {
        //   [columnHeader]: obj[columnHeader]
        // };
        return obj[columnHeader];
      });
      newDataArray.push({
        columnHeader,
        mainArr: filteredMainArr
      });
    });
    this.mainArray = newDataArray;
  },
  saveApi () {
    console.log('this.finalArray', this.finalArray);
    let isError = true; // Default isError as true
    this.phoneErr = false;
    this.firstNameErr = false;
    this.stateErr = false;
    const foundColumns = { phone1: false, first_name: false, state: false };
    // Iterate over the final array to check for required columns
    for (let i = 0; i < this.finalArray.length; i++) {
      const columnName = this.finalArray[i].dbColumnName.toLowerCase();
      if (columnName === 'phone1') {
          foundColumns.phone1 = true;
      }
      if (columnName === 'first_name' || columnName === 'first name') {
          foundColumns.first_name = true;
      }
      if (columnName === 'state') {
          foundColumns.state = true;
      }
    }
    this.phoneErr = !foundColumns.phone1;
    this.firstNameErr = !foundColumns.first_name;
    this.stateErr = !foundColumns.state;

    console.log('this.phoneErr', this.phoneErr);
    console.log('this.firstNameErr', this.firstNameErr);
    console.log('this.stateErr', this.stateErr);
    if (isError) {
      console.log('FALLLLLLLLLLSSSSSSSSSEEEEEEEEEEEEEEEEEEEEE');
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.saveNewListAPi(
        this.listName,
        this.finalArray,
        this.files,
        response => {
          this.importPopup = false
          this.openConfiPopup = false
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$emit('closeSelecion', true)
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
    resizeWindowHandler() {
      if (window.innerWidth < 882) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 548) {
        this.isVerysmall = true;
      } else {
        this.isVerysmall = false;
      }
    },
    getDatabaseDetail () {
      ADMINAPI.getListDatabase(
        response => {
          let array = response.Data !== null ? response.Data : []
          for (let index = 0; index < array.length; index++) {
            array[index].isDisable = false
          }
          this.arrayFromTable = array
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}

.col_name {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.org_name {
  min-width: 320px !important;
  width: 320px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.email_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}

</style>
